<template>
  <div>
    <div class="title-content">
      <BreadCrumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
        <!-- <el-breadcrumb-item :to="{ name: 'test-hub' }">检测中心</el-breadcrumb-item> -->

        <el-breadcrumb-item> 印花税测算</el-breadcrumb-item>
      </BreadCrumb>
      <main-header>
        <template slot="title">印花税列表</template>
      </main-header>
    </div>
    <el-form class="filter-body" @submit.native.prevent="search">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">企业名称：</div>
            <el-input
              class="standard-input input"
              v-model="filter.companyName"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeConsumer"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">纳税人识别号：</div>
            <el-input
              class="input"
              v-model="filter.taxNo"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeProject"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">项目经理：</div>
            <el-input
              class="input"
              v-model="filter.createUserName"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeProject"
              :disabled="!isSuperRole"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">状态：</div>
            <div class="form-control">
              <el-select
                class="input"
                v-model="filter.inspectStatus"
                clearable
                placeholder="请选择"
                @change="search"
              >
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>


        </div>
      </div>
      <div class="btn-wrapper">
        <AwaitButton class="btn btn-primary btn-look-up" @click="search">
          搜索
        </AwaitButton>


      </div>
    </el-form>
    <router-link :to="{name:'stampTax-basicInfo'}" class="btn btn-primary btn-look-test">发起检测
    </router-link>
    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号">
          </el-table-column>
          <el-table-column width="300px" prop="companyName" label="企业名称"></el-table-column>
          <el-table-column min-width="20%" prop="taxNo" label="纳税人识别号"></el-table-column>

          <el-table-column
            prop="createUserName"
            label="项目经理"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>

          <el-table-column label="状态" min-width="10%">
            <template slot-scope="scope">
              <div v-if="scope.row.inspectStatus == 0" class="text-gray-darker">
                <span class="circle gray-darker"></span> 未完成
              </div>
              <div v-if="scope.row.inspectStatus == 1" class="text-blue">
                <span class="circle blue"></span> 已完成
              </div>
              <div v-if="scope.row.inspectStatus == -1" class="text-gray">
                <span class="circle gray"></span> 检测失败
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="检测时间"
            min-width="15%"
            :show-overflow-tooltip="true"
            sortable
          ></el-table-column>
          <el-table-column prop="viewReport" label="操作" width="250">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.inspectStatus==1"
                :to="{
                    name: 'stampTax-result',
                    params:{
                      programId:scope.row.id
                    }
                  }"
                class="manipulator-btn"
              >查看分析结果
              </router-link>
              <a
                v-if="scope.row.inspectStatus==1"
                :href="getExportStampTaxInspectUrl(scope.row.id)"
                target="_blank"
                class="manipulator-btn"
              >下载结果
              </a>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="pagination-wrapper">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[ 10, 20,50, 100]"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper,sizes"
        :total="totalSize"
      ></el-pagination>
    </div>


  </div>
</template>

<script>
import AwaitButton from "await-button";
import filterCacheMixin from "@/mixins/filterCacheMixin";
import {getList} from "@/api/yinhuashui";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import {getExportStampTaxInspectUrl} from "../../../api/yinhuashui";

const moment = require("moment");

export default {
  mixins: [filterCacheMixin],
  components: {
    AwaitButton,
    MainHeader,
    BreadCrumb,
  },

  mounted() {

    sessionStorage.removeItem("isSuccessUpload");
    if (!this.isSuperRole) {
      this.filter.createUserName = this.userInfo.realName
    }
    this.getList();
  },

  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("user")),
      showStatusPro: false,
      checkParts: false,
      dataVal: null,
      uploadStatus: null,
      classObject: "",
      processInfoStatus: false,
      processFalse: false,
      isSuccessUpload: sessionStorage.getItem("isSuccessUpload") || false,
      timestamp: "",
      drawer: false,
      totalSize: 10, //总数
      pageSize: 10,
      pageNum: 0,
      taxNo: "",
      statusOptions: [
        // {
        //   value: 0,
        //   label: "未完成",
        // },
        {
          value: 1,
          label: "已完成",
        },
        {
          value: -1,
          label: "检测失败",
        },
      ],
      generateStatusOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "已生成",
        },
        {
          value: 2,
          label: "待生成",
        },
        // {
        //   value: 3,
        //   label: "已删除"
        // },
        {
          value: 4,
          label: "待完善",
        },
        {
          value: 5,
          label: "生成出错",
        },
      ],
      filter: {
        companyName: "",
        taxNo: "",
        createUserName: '',
        inspectStatus: "",
      },
      tableDataList: null,
      fixData: null,
      // 是否需要补全
      formData: {
        areaBoolean: false,
        kjzdBoolean: false,
        hyBoolean: false,
      },
      closeProcessTag: true,
      coverLoading: false,
      haveShowAuth: true,
    };
  },
  methods: {


    closeProcess() {
      if (!this.closeProcessTag) return;
      this.showStatusPro = false;
      clearTimeout(this.n);
    },


    search() {
      this.pageNum = 0;
      this.saveFilterToCache(this.filter);
      this.getList();
    },
    changeConsumer() {
      if (!this.filter.consumer) {
        this.search();
      }
    },
    changeProject() {
      if (!this.filter.projectName) {
        this.search();
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      //debugger
      this.pageNum = val;
      this.getList();
    },
    reset() {
      this.filter = {
        companyName: "",
        taxNo: "",
        createUserName: '',
        status: "",
      };
      this.getList();
    },
    dateFormatter(row, column) {
      return moment(row.exe_time).format("YYYY-MM-DD HH:mm:ss");
    },
    async getList() {

      let params = {
        ...this.filter,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        firmId:this.firmId
      };


      const res = await getList(params);

      this.tableDataList = res.list;
      this.totalSize = parseInt(res.pageTotal);
      //this.pageNum = resData.data.pageNum;
    },
    getExportStampTaxInspectUrl(programId) {
      return getExportStampTaxInspectUrl(programId)
    }
  },
  computed: {
    isSuperRole() {
      return this.userInfo.authorities.findIndex(v => v.remark == 1) >= 0
    },
    user(){
     return JSON.parse(localStorage.getItem("user"));
    },
    firmId(){
      return this.user.firmId
    }
  },


};
</script>

<style lang="stylus" scoped>
.pagination-wrapper {
  text-align: right;
  align-items: center;
  height: 36px;
  line-height: 36px;
  margin: 20px 30px 20px 0;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
  width auto !important
}

.btn-look-up {
  width auto
}
.btn-look-test {
  margin 0px 20px 0
  font-weight bold;
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.done, .pedding, .delete, .perfect {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
}

.done {
  background: rgba(82, 196, 26, 1);
}

.pedding {
  background: rgba(217, 217, 217, 1);
}

.delete {
  background: rgba(245, 34, 45, 1);
}

.perfect {
  background: rgba(250, 173, 20, 1);
}

.paginations {
  float: right;
  margin-right: 20px;
  margin-bottom: 30px;
}

.fixed-item {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 1000;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.ease {
  transition: all 0.3s;
}

.cursion {
  cursor: pointer;
}

.content-isEdit {
  margin-bottom: 16px;
}

>>> #content-isEdit .el-input {
  width: 416px;
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #content-isEdit .el-cascader {
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input__icon {
  line-height: 36px;
}

>>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.paginationNew {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 40px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(238, 238, 238, 1);
  display: inline-block;
}

.circle.gray {
  background: rgba(0, 0, 0, 0.2);
}

.circle.gray-darker {
  background: rgba(0, 0, 0, 0.4);
}

.circle.blue {
  background: #2F54EB;
}

.circle.cyan {
  background: rgba(24, 144, 255, 1);
}

.circle.green {
  background: rgba(82, 196, 26, 1);
}

.circle.red {
  background: rgba(245, 34, 45, 1);
}

.circle.purple {
  background: rgba(153, 47, 235, 1);
}

.circle.yellow {
  background: rgba(250, 173, 20, 1);
}

.text-gray {
  color: rgba(0, 0, 0, 0.2);
}

.text-gray-darker {
  color: rgba(0, 0, 0, 0.4);
}

.text-blue {
  color: rgba(98, 97, 242, 1);
}

.text-cyan {
  color: rgba(24, 144, 255, 1);
}

.text-green {
  color: rgba(82, 196, 26, 1);
}

.text-red {
  color: rgba(245, 34, 45, 1);
}

.text-purple {
  color: rgba(153, 47, 235, 1);
}

.text-yellow {
  color: rgba(250, 173, 20, 1);
}

.manipulator-btn {
  color: rgba(47, 84, 235, 1);
  background: none;
  border: 0 none;
  cursor: pointer;
  outline-style: none;
  box-shadow: none;
  display inline-block;
  padding 0 12px;
  padding-left 0;
}

.manipulator-btn:hover {
  text-decoration: underline;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

>>> .bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

>>> .el-range-editor.el-input__inner {
  width: 240px;
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> .el-date-editor .el-range-separator {
  line-height: 28px;
}

>>> .el-input__icon {
  line-height: 28px;
}

>>> .el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>> .el-input__icon {
  width: 14px;
}

>>> .el-range-editor.el-input__inner {
  padding: 3px 8px;
}

.showProce {
  color: #2f54eb;
  cursor: pointer;
}

.process-statuss {
  position: fixed;
  top: 20vh;
  left: 50%;
  margin-left: -400px;
}

.process-statusC {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2001;
}

.showProce {
  color: #2f54eb;
  cursor: pointer;
}

.gener {
  color: #2F54EB;
}
.filter-body{
  margin 20px
  display flex;
  align-items center;
}
.filter-body .inputs-wrapper .inputs-wraper-row .form-item {
  width auto

  .label {
    width auto
  }
}
</style>
