import {get, post} from "@/axios";

let root = process.env.VUE_APP_API_ROOT_INDEX;
// console.log(root)

// 获取印花审计检测记录
export const getList = params => {
  return get(`${root}/admin/stampTax/getInspectPage`, params).then(res => res.data);
};


/**
 * 创建印花审计分析
 * @param {{companyName:String,createUserName:String,taxNo:String,firmId:String}} params
 * @returns {*}
 */
export const createStampTaxInspect = params => {
  return post(`${root}/admin/stampTax/createStampTaxInspect`, params).then(res => res.data);
};


/**
 * 批量上传合同文件[印花审计专用]
 * @param {String} programId
 * @returns {`${string}/admin/contract/uploadContExcelByStamp?programId=${string}`}
 */
export const uploadContExcelByStamp = (programId) => {
  return `${root}/admin/contract/uploadContExcelByStamp?programId=${programId}`;
};

/**
 * 批量上传印花税申报表上传
 * @param {String} programId
 * @returns {`${root}admin/excelInfo/uploadExcelEx3?programId=${programId}`}
 */
export const getUploadYinhuashuiUrl = (programId) => {
  return `${root}/admin/excelInfo/uploadExcelEx3?programId=${programId}&zq=2020004`;
};

/**
 * 获取合同台帐明细 前后5条
 * @param {{programId:String}} params
 * @returns {*}
 */
export const getContDetailList = params => {
  return get(`${root}/admin/stampTax/getContDetailList`, params).then(res => res.data);
};


/**
 * 清除合同数据
 * @param {{programId:String}} params
 * @returns {*}
 */
export const clearContData = params => {
  return get(`${root}/admin/stampTax/clearContData`, params).then(res => res.data);
};

/**
 * 清除印花税数据
 * @param {{programId:String}} params
 * @returns {*}
 */
export const clearYinhuashuiData = params => {
  return get(`${root}/admin/excelInfo/delReportDataAll`, params).then(res => res.data);
};


/**
 * 获取印花税申报表列表
 * @param {{programId:String}} params
 * @returns {*}
 */
export const getYHSList = params => {
  return get(`${root}/admin/stampTax/getYHSList`, params).then(res => res.data);
};

/**
 * 提交印花税审计检测
 * @param {{programId:String}} params
 * @returns {*}
 */
export const inspectCommit = params => {
  return get(`${root}/admin/stampTax/inspectCommit`, params).then(res => res.data);
};

/**
 * 删除印花税
 * @param {{programId:String,excelTableName:String,zq:String}} params
 * @returns {*}
 */
export const delReportDataInfo = params => {
  return get(`${root}/admin/excelInfo/delReportDataInfo`, params).then(res => res.data);
};
/**
 * 导出印花审计检测结果
 * @param params
 */
export const getExportStampTaxInspectUrl = programId => {
  return `${root}/admin/stampTax/exportStampTaxInspect?programId=${programId}`;
}

/**
 * 获取印花统计年度集合
 * @param {{programId:String}} params
 * @returns {*}
 */
export const getStampTaxYearList = params => {
  return get(`${root}/admin/stampTax/getStampTaxYearList`, params).then(res => res.data);
};

/**
 * 获取印花税年度统计
 * @param {{programId:String,year:String}} params
 * @returns {*}
 */
export const getContSummaryYearList = params => {
  return get(`${root}/admin/stampTax/getContSummaryYearList`, params).then(res => res.data);
};

/**
 * 获取小微企业免征印花税分析结果
 * @param {{programId:String}} params
 * @returns {*}
 */
export const getMicroEntList = params => {
  return get(`${root}/admin/stampTax/getMicroEntList`, params).then(res => res.data);
};

/**
 * 获取合同台帐印花申报季度差异结果
 * @param {{programId:String}} params
 * @returns {*}
 */
export const getLoanQuarterList = params => {
  return get(`${root}/admin/stampTax/getLoanQuarterList`, params).then(res => res.data);
};


/**
 * 编辑印花年度统计的调整金额
 * @param {{	"adjustAmount": Number,"balance": Number,
 * 	"contractAmountTotal": Number,
 * 	"contractCount": Number,
 * 	"id": Number,
 * 	"programId": String,
 * 	"taxBasis": Number,
 * 	"taxItemName": String,
 * 	"taxItemRate": Number,
 * 	"taxPayable": Number,
 * 	"year": String,
 * 	"yhsAmountTotal": Number
 * }} params
 * @returns {*}
 */
export const editStampTaxYear = (params) => {
  return post(`${root}/admin/stampTax/editStampTaxYear`, params)
    .then(res => res.data);
};
